import { Form, useLoaderData, useNavigation } from "@remix-run/react";
import { TextInput } from "~/components/TextInput";
import { Button } from "~/components/Button";
import { Loader2 } from "lucide-react";
import { useEffect, useRef } from "react";
import {
  isDefaultWorkflow,
  isValidationWorkflow,
  parseParams,
} from "../signup/utils/workflow-type";
import { json, LoaderFunctionArgs } from "@remix-run/node";
export { action } from "./action";

export { ErrorBoundary } from "~/components/RemixErrorBoundary";

export async function loader({ request }: LoaderFunctionArgs) {
  const { workflowType } = parseParams(request).getWorkflowType().extract();
  return json({ workflowType });
}

export default function Route() {
  const { workflowType } = useLoaderData<typeof loader>();

  const navigation = useNavigation();
  const loading = navigation.state !== "idle";
  const emailRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  return (
    <>
      <div className="text-center">
        <div />

        <img
          className="w-[60%] mx-auto mb-7"
          src="/assets/images/ninjas-animation.gif"
          alt="Ninja Juggling"
        />

        <h1 className="text-xl sm:text-[28px] font-semibold text-secondary">
          Welcome to Ninjalerts
        </h1>

        {isValidationWorkflow(workflowType) && (
          <img
            className="w-[50%] mx-auto mt-6 mb-5"
            src="/assets/images/logo/discord-logo-blue.svg"
            alt="Discord"
          />
        )}

        {isDefaultWorkflow(workflowType) ? (
          <p className="text-sm sm:text-base mt-3 text-secondary/80">
            Enter your email to receive a unique verification code
          </p>
        ) : (
          <p className="text-sm sm:text-base mt-2 text-secondary/80">
            Enter your email to start Discord verification
          </p>
        )}

        <Form method="POST">
          <div className="mt-5 mb-4">
            <TextInput
              type="email"
              name="email"
              placeholder="Email"
              className="w-full px-4 py-4"
              ref={emailRef}
            />
          </div>

          <div className="mt-auto">
            <Button variant="default" size="xl" className="w-full" type="submit" disabled={loading}>
              Send verification code
              {loading && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}
